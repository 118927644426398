// 'use client';

import localFont from 'next/font/local';
// import { css } from 'styled-components';
import { Roboto_Flex, Inter as InterFont, Mulish as MulishFont } from 'next/font/google';

export const Roboto = Roboto_Flex({ subsets: ['latin'] });

export const Inter = InterFont({
  subsets: ['latin'],
  variable: '--font-inter',
  display: 'swap',
});

export const Mulish = MulishFont({
  subsets: ['latin'],
  variable: '--font-mulish',
  display: 'swap',
});

export const BWseidoRaw = localFont({
  src: [
    {
      path: './bwseidoraw-regular-webfont.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './bwseidoraw-medium-webfont.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: './bwseidoraw-bold-webfont.woff2',
      weight: 'bold',
      style: 'normal',
    },
    {
      path: './bwseidoraw-black-webfont.woff2',
      weight: '800',
      style: 'normal',
    },
  ],
  variable: '--font-bwseido-raw',
});

export const cssFontVars = () => {
  return `
    --font-family-roboto: ${Roboto.style.fontFamily};
    --font-family-inter: ${Inter.style.fontFamily};
    --font-family-bwseido-raw: ${BWseidoRaw.style.fontFamily};
    --font-family-mulish: ${Mulish.variable};
  `;
};
