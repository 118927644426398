import * as Styled from './styled';
import { Cross1Icon } from '@radix-ui/react-icons';
import Button from '@/ui/Button';

export type TFlashType = 'info' | 'success' | 'warning' | 'danger' | 'default';

type FlashProps = {
  style?: React.CSSProperties;
  center?: boolean;
  type?: TFlashType;
  position?: 'inline' | 'header';
  children: React.ReactNode;
  onClose?: () => void;
};

const FlashCloseButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Styled.FlashCloseButton>
    <Button.Text onClick={onClick}>
      <Cross1Icon />
    </Button.Text>
  </Styled.FlashCloseButton>
);

const Flash: React.FC<FlashProps> = ({ children, style, onClose, center, type = 'default', position = 'inline' }) => {
  const StyledComponent = {
    info: Styled.FlashInfo,
    success: Styled.FlashSuccess,
    warning: Styled.FlashWarning,
    danger: Styled.FlashDanger,
    default: Styled.Flash,
  }[type];

  return (
    <StyledComponent style={style} $center={center} $position={position}>
      {onClose && <FlashCloseButton onClick={onClose} />}
      {children}
    </StyledComponent>
  );
};

// Export individual components through object assignment
export default Object.assign(Flash, {
  Info: (props: Omit<FlashProps, 'type'>) => <Flash {...props} type="info" />,
  Success: (props: Omit<FlashProps, 'type'>) => <Flash {...props} type="success" />,
  Warning: (props: Omit<FlashProps, 'type'>) => <Flash {...props} type="warning" />,
  Danger: (props: Omit<FlashProps, 'type'>) => <Flash {...props} type="danger" />,
  Error: (props: Omit<FlashProps, 'type'>) => <Flash {...props} type="danger" />,
});
