import { css } from 'styled-components';
import { Roboto, Inter, Mulish } from './index';

export const injectCssFontVarsStorybook = () => css`
  @font-face {
    font-family: 'BWseidoRaw';
    src:
      local('BWseidoRawRegular'),
      url('/src/styles/fonts/bwseidoraw-regular-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'BWseidoRaw';
    src:
      local('BWseidoRawMedium'),
      url('/src/styles/fonts/bwseidoraw-medium-webfont.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'BWseidoRaw';
    src:
      local('BWseidoRawBold'),
      url('/src/styles/fonts/bwseidoraw-bold-webfont.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'BWseidoRaw';
    src:
      local('BWseidoRawBlack'),
      url('/src/styles/fonts/bwseidoraw-black-webfont.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
  }
`;

export const cssFontVarsStorybook = () => {
  return `
    --font-family-roboto: ${Roboto.style.fontFamily};
    --font-family-inter: ${Inter.style.fontFamily};
    --font-bwseido-raw: 'BWseidoRaw', sans-serif;
    --font-mulish: ${Mulish.variable};
    --font-family-mulish: ${Mulish.variable};
  `;
};
